import ScrollHint from 'scroll-hint'

// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性

$(function () {
	$(document).on('click', '.opennav', function () {
		$('#header').toggleClass('is-active');
	})
	new ScrollHint('.js-scrollable', {
		i18n: {
			scrollable: 'スクロールできます'
		}
	});
});
